.custom-dropdown {
    position: relative;
    width: 100%;
  }
  
  .custom-dropdown .selected-option {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 15px;
    cursor: pointer;
  }
  
  .custom-dropdown .selected-option img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .custom-dropdown .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index:2000;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .custom-dropdown .options li {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .custom-dropdown .options li:hover {
    background-color: #efeeee;
  }
  
  .custom-dropdown .options li img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .custom-dropdown .options li[aria-selected="true"] {
    background-color: #e0e0e0;
    color: black;
  }
  

  .edit-article-modal .article-coordinate {
    position: relative;
  }
  
  .edit-article-modal .article-coordinate .custom-dropdown {
    position: static;
  }
