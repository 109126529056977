.logout-button{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 7% 20%;
    margin-left: 10%;
    font-size: medium;
    cursor: pointer;
    color:rgba(227, 31, 37);
    border: 3px solid rgba(227, 31, 37);
    border-radius: 5px;
}
.logout-button button{
    background: none;
    border: none;
    color: rgba(227, 31, 37);
    font-size: small;
    font-weight: 600;
    margin-right: 10%;
    cursor: pointer;
    outline: none;
}