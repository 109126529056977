*{
    padding: 0%;
    margin: 0%;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}
.login-page{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.login-page img{
    height: 120px;
    width: auto;
}
.login-form{
    width: 30%;
    height: 60%;  
    margin-top: -3%;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: end;  
    gap: 2%;
    letter-spacing: 1px;
}
.input{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.input input{
    width: 100%;
    padding: 2.5%;
    margin-top: -2%;
    font-size: medium;
    background-color: rgb(239, 238, 238);
    border: 2px solid rgb(208, 207, 207);
}
.button{
    width: 35%;
    padding: 2% 5%;
    margin-top: 5px;
    cursor: pointer;
    color: white;
    font-size: medium;
    letter-spacing: 1px;
    border-radius: 5px;
    border: 1px solid rgb(141, 19, 23);
    background-color:rgba(227, 31, 37);
}
.credits{
    position: relative;
    top:  5%;
}

@media (max-width: 950px) {
    .login-form {
        width: 40%;
        height: 50%;
        margin-top: 0;
        letter-spacing: 0%;
    }

    .button {
        width: 40%;
    }
}

@media (max-width: 900px) {
    .login-form {
        width: 50%;
        height: 50%;
        margin-top: 0;
        letter-spacing: 0%;
    }

    .button {
        width: 40%;
    }
}

@media (max-width: 800px) {
    .login-form {
        width: 60%;
        height: 50%;
        margin-top: 0;
        letter-spacing: 0%;
    }

    .button {
        width: 40%;
    }
}

@media (max-width: 700px) {
    .login-form {
        width: 60%;
        height: 50%;
        margin-top: 0;
        letter-spacing: 0%;
    }
    .button {
        width: 40%;
    }
}

@media (max-width: 600px) {
    .login-form {
        width: 60%;
        height: 50%;
        margin-top: 0;
        letter-spacing: 0%;
    }
    .button {
        width: 40%;
    }
    .login-page img {
        height: 100px;
        width: auto;
    }
}

@media (max-width: 500px) {
    .login-form {
        width: 60%;
        height: 50%;
        margin-top: 0;
        letter-spacing: 0%;
    }
    .button {
        width: 40%;
    }
    .login-page img {
        height: 90px;
        width: auto;
    }
}