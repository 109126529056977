.home-page{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.home-page-nav{
    width: 100%;
    height: 15vh;
    padding: 3% 7% 3% 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-page-nav img{
    height: 80px;
    width: auto;
}
.home-page-nav h3{
    font-size: 1.5em;
    letter-spacing: 1px;
}
.home-page-nav .logout{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}
.home-page-nav .logout p{
    font-size: larger;
    font-weight: 500;
}
.home-page-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.home-page-search-1{
    margin-left: 3.2%;
}

.home-page-search-1 button{
    color: rgba(227, 31, 37);
    font-weight: bolder;
    background: transparent;
    border: 2px solid rgba(227, 31, 37);
    font-size: small;
    font-weight: bold;
    padding: 8%;
    width: 150px;
    border-radius: 7px;
    cursor: pointer;
}

.home-page-search-2{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap:20px;
    padding: 1% 3.2% 1% 0%;
}
.home-page-search-2 .search{
    background-color: rgb(234, 235, 237);
    padding: 2%;
    width: 320px;
    border-radius: 25px;
    border: 1px solid rgb(151, 150, 150);
    flex-grow: 1rem;
}
.home-page-search-2 .search .icon{
    color: rgb(84, 84, 84);
}
.home-page-search-2 .search input{
    background: none;
    border: none;
    padding-left: 15px;
    outline: none;
    font-size: medium;
}
.home-page-search-2 button{
    color: white;
    background-color: rgba(227, 31, 37);
    border: none;
    font-size: small;
    font-weight: bold;
    padding: 2.5% 2%;
    width: 150px;
    border-radius: 7px;
    cursor: pointer;
}

.news-articles-div{
    font-size: small;
    font-weight: 600;
}
.news-articles-div table { 
    padding: 0.1% 5%;
}
.news-articles-div table thead{
    text-align: left;
}
.news-articles-div table tbody{
    color: rgb(91, 90, 90);
}
.news-articles-div table .row th, .news-articles-div table .row td{
    border-bottom: 1px solid #ccc;
    padding: 1.5% 1%;
    text-align: left;
    line-height: 20px;
}
.row-action{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50px;
    font-size: larger;
}
.row-action .action{
    cursor: pointer;
}
.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1% 2%;
    gap: 1rem;
    margin-right: 3%;
    font-size: small;
    color: rgb(72, 70, 70);
}

.pagination div {
    display: flex;
    align-items: center;
}

.pagination select {
    padding: 0.25rem;
    border: none;
    color: rgb(72, 70, 70);
    margin-right: 10px;
    outline: none;
    cursor: pointer;
}

.pagination button {
    background-color: #fff;
    color: #333;
    padding: 0.5rem 0.7rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
    font-weight: bold;
}

.pagination button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
}
  
.loading-spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}  

.input-disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}
  
.error-message {
    color: #e31f25;
    margin-left: 90px;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .home-page-nav {
        flex-direction: column;
        height: auto;
        padding: 2% 5% 2% 2%;
    }

    .home-page-nav img {
        height: 60px;
        margin-bottom: 2%;
    }

    .home-page-nav h3 {
        font-size: 1.2em;
        margin-bottom: 1%;
    }

    .home-page-search {
        flex-direction: row;
        align-items: last baseline;
        justify-content: center;
        gap: 0.8%;
        padding: 1% 2% 1% 0%;
    }

    .home-page-search .search {
        width: 60%;
        max-width: none;
    }

    .home-page-search button {
        width: 30%;
        margin-top: 10px;
        padding: 1% 2%;
    }

    .news-articles-div table {
        padding: 0.1% 2%;
    }
}

@media (max-width: 480px) {
    .home-page-nav {
        padding: 2% 3% 2% 2%;
    }

    .home-page-nav img {
        height: 50px;
    }

    .home-page-nav h3 {
        font-size: 1em;
    }

    .home-page-search {
        padding: 1% 1.5% 1% 0%;
    }

    .home-page-search .search {
        width: 100%;
    }

    .news-articles-div table .row th,
    .news-articles-div table .row td {
        padding: 1% 0.5%;
    }

    .pagination {
        justify-content: center;
    }

    .pagination button {
        padding: 0.4rem 0.6rem;
    }
}