.confirm-delete-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirm-delete-content {
    background-color: white;
    padding: 2% 5%;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.confirm-delete-content h2 {
    margin-top: 0;
    margin-bottom: 4%;
}

.confirm-delete-buttons {
    margin-top: 7%;
}

.cancel-btn,
.confirm-btn {
    cursor: pointer;
    background-color: #ddd;
    color: #333;
    border: none;
    padding: 3% 9%;
    margin: 0 10px;
    border-radius: 5px;
}

.confirm-btn {
    background-color: #e31f25;
    color: white;
}

.cancel-btn:hover{
    background-color: #bbb;    
}
.confirm-btn:hover {
    background-color: rgb(199, 24, 30);
}