.select-region-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.select-region-modal-container {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 5px;
}

.select-region-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.select-region-modal-header h2 {
    font-size: 25px;
    font-weight: 500;
    color: #333;
}

.select-region-modal-close-btn {
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    transition: color 0.3s ease;
}

.select-region-modal-close-btn:hover {
    color: #1f2937;
}

.select-region-modal-search {
    padding: 16px;
}

.select-region-modal-search input {
    width: 100%;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    font-size: 1rem;
}

.select-region-modal-list {
    overflow-y: auto;
    height: 400px;
}

.select-region-modal-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.select-region-modal-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.select-region-modal-list li:hover {
    background-color: #f3f4f6;
}

.select-region-modal-list li.selected {
    background-color: #e6f2ff;
}

.select-region-modal-list .country-checkbox {
    margin-right: 12px;
}

.select-region-modal-list .country-name {
    flex-grow: 1;
}

.select-region-modal-list .country-code {
    color: #6b7280;
    font-size: 0.875rem;
}

.select-region-modal-no-results {
    text-align: center;
    padding: 16px;
    color: #6b7280;
}

.select-region-modal-save-btn {
    padding: 16px;
    border-top: 1px solid #e5e7eb;
    text-align: right;
}

.select-region-modal-save-btn button {
    width: 40%;
    padding: 12px;
    background-color: rgba(227, 31, 37);
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

@media (max-width: 600px) {
    .select-region-modal-container {
        width: 95%;
        max-width: none;
        margin: 0 10px;
    }
}