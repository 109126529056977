.add-news-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-news-content {
    width: 50%;
    padding: 1.5% 2%;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.add-news-content h2 {
    margin-bottom: 10px;
    font-size: 25px;
    text-align: left;
    font-weight: 500;
}

.add-news-content .add-news-fields {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 5%;
    margin-top: 3%;
    margin-bottom: 20px;
}

.add-news-content .add-news-field {
    display: flex;
    flex-direction: column;
    width: 100%; 
}


.add-news-content .add-news-fields label {
    font-size: small;
    margin-bottom: 1.5%;
    letter-spacing: 0.1px;
}

.add-news-fields input, .add-news-field select, .add-news-field textarea {
    width: 100%;
    height: 40px;
    background-color: #eeecec;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0px 10px;
    font-size: 15px;
}

.add-news-fields .add-news-field .autocomplete {
    width: inherit;
}

.add-news-content .add-news-field {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
}

.buttons {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2pc;
}

/* .color-picker-container .pick-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
} */

.buttons .submit {
    padding: 9px 25px;
    border: none;
    color: white;
    font-size: medium;
    border-radius: 5px;
    background-color: rgba(227, 31, 37);
    cursor: pointer;
}

.buttons .submit:hover {
    background-color: rgb(199, 24, 30);
}

.buttons .cancel {
    background: none;
    color: rgba(227, 31, 37);
    font-size: medium;
    font-weight: bold;
    border: none;
    cursor: pointer;
}

.file-input-wrapper {
    position: relative;
    width: 100%;
}

.file-input-wrapper input {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: #eeecec;
}

.file-input-label {
    display: flex;
    justify-content: space-between;
    grid-template-columns: 60% 40%;
    align-items: center;
    padding: 1%;
    width: 100%;
    height: 40px;
    background-color: #eeecec;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.file-input-button {
    background-color: rgba(227, 31, 37);
    color: white;
    padding: 7px 10px;
    text-align: center;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.add-news-field select {
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 15px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
}

.color-picker-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.select-color-button {
    padding: 10px 15px;
    cursor: pointer;
    background-color: #eeecec;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid #ccc;
    color: #716d6d;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.color-display {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.color-picker-overlay {
    position: absolute;
    z-index: 1000;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.292);
    margin-top: -150px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 225px;
    margin-left: 150px;
}

.ok-button {
    margin-top: 10px;
    padding: 5px 20px;
    border: none;
    background-color: rgba(227, 31, 37);
    color: white;
    border-radius: 4px;
    cursor: pointer;
}



