.edit-article-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 20px 0;
}

.edit-article-content {
    width: 50%;
    padding: 1.5% 2%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.edit-article-content h2 {
    margin-bottom: 20px;
    font-size: 25px;
    text-align: left;
    font-weight: 500;
}

.article-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.article-info small {
    font-size: small;
    margin-bottom: 5px;
    letter-spacing: 0.1px;
}

.article-info textarea,
.article-info input,
.article-info select,
.article-coordinates input,
.article-coordinates select {
    width: 100%;
    height: 40px;
    background-color: #eeecec;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 15px;
    color: #333;
}

.article-info textarea {
    resize: vertical;
    min-height: 40px;
}

.article-coordinates {
    margin-bottom: 20px;
}

.article-coordinates .lat-lng {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
}

.article-coordinates_auto{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.article-coordinate {
    display: flex;
    flex-direction: column;
}

.article-coordinate small {
    font-size: small;
    margin-bottom: 5px;
    letter-spacing: 0.1px;
}

.autocomplete input {
    width: 100%;
    height: 40px;
    background-color: #eeecec;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 15px;
}

/* Color picker styles */
.color-picker-container {
    position: relative;
    width: 100%;
}

.select-color-button {
    width: 100%;
    height: 40px;
    padding: 8px 10px;
    background-color: #eeecec;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.color-name {
    color: #333;
    font-size: 15px;
}

.color-display {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.color-picker-overlay {
    position: absolute;
    z-index: 1000;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    top: -180px;
    left: 50%;
    transform: translateX(-50%);
}

.ok-button {
    margin-top: 10px;
    padding: 8px 20px;
    border: none;
    background-color: rgba(227, 31, 37);
    color: white;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.ok-button:hover {
    background-color: rgb(199, 24, 30);
}

.edit-article-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}

.update-button {
    padding: 9px 25px;
    border: none;
    color: white;
    font-size: medium;
    border-radius: 5px;
    background-color: rgba(227, 31, 37);
    cursor: pointer;
}

.update-button:hover {
    background-color: rgb(199, 24, 30);
}

.cancel-button {
    background: none;
    color: rgba(227, 31, 37);
    font-size: medium;
    font-weight: bold;
    border: none;
    cursor: pointer;
}
